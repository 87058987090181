.new-shop {
    h2 {
        text-align: center;
        font-weight: 700;
    }

    .page-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        h5 {
            font-size: 16px;
            font-weight: 600;
            color: #212529;
            margin-top: 0px;
        }

        .sort-by__control {
            width: 150px;
            border: 1px solid #E2E9E1;
            font-size: 14px;

            .sort-by__indicator-separator {
                display: none;
            }
        }

        .sort-by__option {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .products-list {
        .filters-card {
            .filters-card-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #FFF;
                padding: 10px 15px;

                h5 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }

                .clear-filters-btn {
                    background-color: #FFF;
                    padding: 0px;
                    font-weight: 600;
                    color: #3E3EF4;
                    font-size: 14px;
                    border: 0px solid transparent;
                }
            }

            .filters-card-body {
                .filter-category {
                    .price {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        p {
                            color: grey;
                            margin-bottom: 0px;
                            font-weight: 500;
                        }
                    }

                    h5 {
                        font-size: 15px;
                        font-weight: 600;
                        margin-bottom: 0px;
                        margin-top: 0px;
                    }

                    .category-checkbox {
                        margin-top: 10px;
                        
                        .checkbox-control {
                            .form-check-label {
                                font-size: 14px;
                                font-weight: 500;
                            }
                        }
                    }

                    .ratings-checkbox {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;

                        .checkbox-control {
                            margin-right: 5px;
                        }

                        .rating-details {
                            display: flex;
                            align-items: center;

                            p {
                                margin-left: 5px;
                                font-weight: 500;
                                font-size: 14px;
                            }
                        }
                    }

                    .pricing-range {
                        margin-top: 15px;
                    }

                    .range-slider {
                        height: 5px;
                        background-color: #F4F4F4;
                    }

                    .range-slider .range-slider__thumb {
                        width: 18px;
                        height: 18px;
                        border: 1px solid rgba($color: #000000, $alpha: 0.1);
                        background-color: #FFF;
                        box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
                    }

                    .range-slider .range-slider__range {
                        background-color: #3E3EF4;
                    }
                }
            }
        } 

        .list-table {
            .list-table-body {
                padding: 0px;

                .products-list-table {
                    border-radius: 5px;

                    .rdt_Table {
                        /* TABLE HEAD */
                        .rdt_TableHead {
                            .rdt_TableHeadRow {
                                min-height: 30px;
                                background: #F5F6FA;
                                justify-content: space-between;

                                .rdt_TableCol {
                                    font-size: 12px;
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    color: #8094AE;
                                }
                            }
                        }

                        /* TABLE BODY */
                        .rdt_TableBody {
                            .rdt_TableRow {
                                padding: 10px 0px;
                                justify-content: space-between;
                                .rdt_TableCell {
                                    min-width: auto;
                                    text-overflow: clip;

                                    .table-img {
                                        width: 100px;
                                        height: auto;
                                        border-radius: 5px;
                                    }

                                    .product-name {
                                        font-size: 15px;
                                        font-weight: 600;
                                        margin-bottom: 0px;
                                        margin-top: 0px;
                                        white-space: break-spaces;
                                    }

                                    .product-category {
                                        margin-bottom: 0px;
                                        font-size: 14px;
                                        font-weight: 400;
                                    }

                                    .product-sku {
                                        margin-bottom: 0px;
                                        font-size: 14px;
                                        font-weight: 400;
                                    }

                                    .qty-btn {
                                        background-color: #3E3EF4;
                                        border-color: #3E3EF4;
                                        border-radius: 50%;
                                        padding: 8px 12px;
                                    }

                                    .react-select {
                                        overflow: auto;
                                        overflow-y: hidden;

                                        .color-selection-control__control {
                                            width: 160px;
                                            border: 1px solid #E2E9E1;
                                    
                                            .color-selection-control__indicator-separator {
                                                display: none;
                                            }
                                        }
                                    }

                                    .form-control {
                                        height: 38px;
                                    }

                                    .color-select-label {
                                        font-family: 'Figtree', sans-serif !important;
                                        color: #8E8E90 !important;
                                        line-height: normal !important;
                                        font-weight: 500 !important;
                                    }

                                    .custom-color-options {
                                        width: 160px;
                                        height: 40px;

                                        .MuiOutlinedInput-notchedOutline {
                                            border: 1px solid #E2E9E1 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.color-options {
    display: flex;
    align-items: center;

    .color-code {
        height: 25px;
        width: 25px;
        border-radius: 50%;
    }

    p {
        padding-left: 10px;
        font-weight: 500;
        font-size: 13px;
    }
}

.disabled-option {
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
}
@media (max-width: 575px) {
  /* FOOTER */
  .footer .copyright-info .copyright-links {
    display: block;
  }

  .footer .copyright-info .copyright-links .security-verifications {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }

  .footer .copyright-info .copyright-links .security-verifications img {
    margin-left: 0px;
  }

  .footer .copyright-info .copyright-links .info p {
    text-align: center;
  }

  .footer .copyright-info .copyright-links .info .links {
    justify-content: center;
  }

  .links-list {
    margin-top: 15px;
  }

  /* GIFT OF GLAM */
  .youtube-video .video-frame {
    height: 300px;
  }

  .home .gift-of-glam .youtube-video .glam-link {
    font-size: 16px;
  }

  /* ART OF THE BROW */
  .home .art-of-the-brow .youtube-video {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .home .art-of-the-brow .youtube-video .glam-link {
    font-size: 16px;
    margin-top: 0px;
  }

  /* BROW BAR BY REEMA */
  .home .browbar-by-reema .youtube-video .glam-link {
    font-size: 16px;
    margin-top: 0px;
  }

  /* HEADER */
  .header .top-header h5 {
    line-height: 25px;
    font-size: 14px;
  }

  .header .top-header-two h5 {
    font-size: 14px;
  }

  .home .bestsellers .section-heading {
    display: block;
    text-align: center;
  }

  .press .articles-content .article-link {
    font-size: 16px;
    margin-top: 0px;
  }

  .press .shopping-collection .collection-card .collection-link {
    font-size: 16px;
  }

  .about-us .about-content {
    padding-left: 0px !important;
    margin-top: 10px;
  }

  .about-us .about-content-2 {
    padding-right: 0px !important;
    margin-top: 10px;
  }

  .about-us h5 {
    font-size: 16px;
  }

  .about-us .shopping-collection .collection-card .collection-link {
    font-size: 16px;
  }

  .cart-popup {
    width: 350px;
    right: -120px;
    margin-left: auto;
    margin-right: auto;
  }

  .cart-popup .products-added .paypal-btn {
    margin-top: 10px;
  }

  .cart .summary-heading {
    margin-top: 15px;
  }

  .cart .bestsellers .section-heading {
    display: block;
    text-align: center;
  }

  .cart .cart-items .shopping-items .product-details .product-name {
    font-size: 14px;
  }

  .account .login_wrap {
    margin-top: 15px;
  }

  .checkout .checkout-tabnames .checkout-tabnames-item a {
    font-size: 20px;
  }

  .checkout .checkout-tabnames .checkout-tabnames-item {
    margin-right: 0px;
  }

  .checkout .shipping-address h5 {
    font-size: 14px;
  }

  .select-control__control {
    margin-bottom: 15px;
  }

  .checkout .summary-heading {
    margin-top: 20px;
  }

  .cart-items {
    overflow-x: scroll;
  }

  .cart .cart-items .shopping-items .product-details {
    max-width: 400px;
    width: 400px;
  }

  .cart .cart-items .shopping-items {
    width: 750px;
  }

  .cart .cart-items .shopping-items .product-details p {
    font-size: 14px;
  }

  .qty-width .select-control__control {
    margin-bottom: 0px;
  }

  .product-detail .product-description {
    margin-top: 15px;
  }

  .product-detail .product-description .good-to-know .good-to-know-list {
    display: block;
  }

  .product-detail
    .product-description
    .good-to-know
    .good-to-know-list
    .list-detail {
    margin-bottom: 10px;
  }

  .product-detail .product-description .quantity-counter {
    margin-bottom: 10px !important;
    justify-content: center;
  }

  .product-detail .total-reviews .reviews-actions {
    display: block;
    justify-content: center;
    text-align: center;
  }

  .product-detail .total-reviews .reviews-actions .review-count {
    justify-content: center;
    margin-bottom: 10px;
  }

  .user-review-desc {
    margin-top: 15px;
  }

  .product-detail
    .total-reviews
    .user-review
    .user-review-desc
    .ratings-given
    .rating-name {
    margin-right: 15px;
  }

  .product-detail
    .total-reviews
    .user-review
    .user-review-desc
    .client-images-list
    img {
    height: 60px;
    width: 60px;
    margin-bottom: 10px;
  }

  .product-detail .bestsellers .section-heading {
    text-align: center;
    display: block;
  }

  .product-detail .bestsellers .section-heading .shop-products {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }

  .product-detail .secondary-images-list {
    display: flex;
    height: auto;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: space-between;
  }

  .product-detail .secondary-images-list img {
    height: 80px;
    width: 80px;
  }

  .orders-info {
    margin-top: 15px;
  }

  .account-info {
    margin-top: 15px;
  }

  .resp-margin {
    margin-top: 15px;
  }

  .address-information {
    margin-top: 15px;
  }

  .my-account .address-information .shipping-addresses .address-box {
    margin-bottom: 15px;
  }

  .user-acc-info {
    margin-top: 15px;
  }

  .brow-quiz {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .brow-quiz .quiz-banner {
    border-radius: 0px !important;
  }

  .product-detail .primary-img img {
    width: 100%;
  }

  .product-detail .product-small-images {
    width: 100%;
  }

  .newsletter-modalbody .newsletter-description {
    background-color: #fff;
  }

  .newsletter-modalbody img {
    display: none;
  }

  .newsletter-modal {
    padding-left: 0px !important;
  }

  .wholesale .login-height {
    height: calc(95vh - 80px) !important;
  }

  .wholesale .copyright-info .copyright-links {
    display: block;
  }

  .wholesale .copyright-info .copyright-links .security-verifications {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }

  .wholesale .copyright-info .copyright-links .security-verifications img {
    margin-left: 0px;
  }

  .wholesale .copyright-info .copyright-links .info p {
    text-align: center;
  }

  .registration-form .copyright-info .copyright-links .info .links {
    justify-content: center;
  }

  .registration-form .copyright-info .copyright-links {
    display: block !important;
  }

  .registration-form .copyright-info .copyright-links .security-verifications {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }

  .registration-form
    .copyright-info
    .copyright-links
    .security-verifications
    img {
    margin-left: 0px !important;
  }

  .registration-form .copyright-info .copyright-links .info p {
    text-align: center;
  }

  .registration-form .copyright-info .copyright-links .info .links {
    justify-content: center;
  }

  .registration-form .form-steps .step-box {
    margin-top: 20px;
  }

  .registration-form
    .form-steps
    .address-details
    .form
    .form-group
    .property-type
    .type-box
    .checkbox-control {
    display: block !important;
  }

  .registration-form
    .form-steps
    .address-details
    .form
    .form-group
    .property-type
    .type-box
    .checkbox-control
    .form-check-input {
    margin-top: 10px !important;
  }

  .list-table {
    margin-top: 20px;
  }

  .registration-landing .banner h1 {
    font-size: 35px !important;
  }

  .registration-landing .banner h5 {
    font-size: 20px !important;
  }

  .registration-landing .banner .category-box {
    margin-bottom: 20px;
  }

  .registration-landing .banner .retail-box {
    margin-top: 0px !important;
  }

  .registration-landing .account-holder-benefits img {
    width: 100% !important;
  }

  .benefits-text {
    margin-top: 20px !important;
  }

  .wholesale-page .page-wrap .step-box {
    margin-bottom: 15px;
    height: auto !important;
  }

  .resale-certificates h1 {
    font-size: 30px !important;
  }

  .wholesale-shop .product-list .product-box .details .sku {
    display: block;
    margin-left: 0px !important;
  }

  .resp-flex {
    display: block !important;
  }

  .wholesale-shop .product-list .product-box .details .suggested-retail-price {
    margin-bottom: 10px !important;
  }

  .wholesale-shop .product-list .product-box .details .shades-selection {
    padding: 10px 10px !important;
  }

  .wholesale-shop
    .product-list
    .product-box
    .details
    .shades-selection
    .shade-row {
    display: block !important;
    margin-bottom: 20px;
  }

  .wholesale-shop
    .product-list
    .product-box
    .details
    .shades-selection
    .shade-row
    .shade-present {
    justify-content: center;
    margin-bottom: 10px;
    width: 100% !important;
  }

  .wholesale-shop
    .product-list
    .product-box
    .details
    .shades-selection
    .shade-row
    .add-to-cart {
    width: 100%;
  }

  .wholesale-shop
    .product-list
    .product-box
    .details
    .shades-selection
    .shade-row
    .quantity-counter {
    margin-bottom: 10px !important;
    
    justify-content: center;
  }
  .suggested-retail-price-blur-box {

    top:25px;

    height: 70px !important;
  }
  .suggested-retail-price {
    margin-top: 0px !important;
  }

  .new-header .top-header h5 {
    font-size: 14px !important;
    font-weight: 600;
    margin-top: 0px !important;
    line-height: 24px !important;
  }

  .new-header .top-header-two .switcher-tabs {
    position: relative !important;
    margin-top: 10px;

    .switcher-box {
      width: 100%;
    }
  }

  .new-header .top-header-two {
    padding-bottom: 0px !important;
  }

  .new-header .new-header-wrap .header-flex .header-right .header-actions {
    margin-right: 10px !important;
  }

  .new-header .new-header-wrap .header-flex .header-right .header-actions:last-child {
    margin-right: 0px !important;
  }

  .registration-landing .banner .category-box .text h5 {
    font-size: 18px !important;
    line-height: 26px !important;
  }

  .registration-landing .banner .category-box {
    height: auto !important;
  }
}

.registration-form {

    .container {
        max-width: 1200px !important;
    }

    .form-steps {
        padding: 50px 0px;

        .logo {
            display: flex;
            justify-content: center;
        }

        .step-box {
            width: 100%;
            border-radius: 5px;
            background-color: #F4F4F4;
            padding: 15px 20px;
            text-align: center;

            .step-number {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                border: 2px solid #000000;
                margin-left: auto;
                margin-right: auto;

                h5 {
                    font-size: 22px;
                    font-weight: 700;
                }
            }

            .step-details {
                margin-top: 15px;
                h5 {
                    font-weight: 600;
                    margin-bottom: 0px;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 15px;
                    margin-top: 5px;
                }
            }
        }

        .contact-details {
            margin-top: 20px;
            
            h5 {
                font-weight: 600;
                font-size: 18px;
            }

            .requirements-list {
                li {
                    margin-bottom: 5px;

                    a {
                        margin-left: 5px;
                        text-decoration: none;
                    }
                }
            }

            .form {
                background-color: #F4F4F4;
                border-radius: 5px;
                width: 100%;
                padding: 20px;
            }

            .proceed-btn {
                background-color: #000;
                font-size: 14px;
                font-weight: 600;
                padding: 10px 15px;
                border-color: #000;
            }
        }

        .address-details {
            margin-top: 20px;

           .form {
                background-color: #F4F4F4;
                border-radius: 5px;
                width: 100%;
                padding: 20px;

                .form-group {
                    .property-type {
                        display: flex;
                        align-items: center;

                        .type-box {
                            background-color: #FFF;
                            border: 1px solid rgba($color: #000000, $alpha: 0.1);
                            border-radius: 5px;
                            padding: 15px;
                            margin-right: 15px;

                            .checkbox-control {
                                display: flex;
                                align-items: center;
                                margin-bottom: 0px;
                                min-height: auto;

                                .form-check-label {
                                    font-size: 15px;
                                    font-weight: 500;
                                    padding-left: 10px;
                                    margin-bottom: -3px;
                                }

                                .form-check-input {
                                    height: 1.2rem;
                                    width: 1.2rem;
                                    margin-top: auto;
                                }
                            }
                        }
                    }
                }
            } 

            .proceed-btn {
                background-color: #000;
                font-size: 14px;
                font-weight: 600;
                padding: 10px 15px;
                border-color: #000;
            }
        }

        .other-information {
            margin-top: 20px;

            .form {
                border-radius: 5px;
                width: 100%;
                padding: 20px;
            }

            .documents {
                background-color: #F4F4F4;
            }

            .other-info {
                margin-top: 20px;
                background-color: #F4F4F4;

                .terms-conditions {
                    margin-bottom: 15px;
                    h5 {
                        font-weight: 600;
                        margin-bottom: 0px;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 15px;
                        margin-top: 5px;
                    }

                    .terms-conditions-box {
                        width: 100%;
                        height: 300px;
                        overflow: auto;
                        overflow-x: hidden;
                        overflow-y: scroll;
                        background-color: #FFF;
                        border: 1px solid rgba($color: #000000, $alpha: 0.1);
                        border-radius: 5px;
                        margin-top: 15px;
                        padding: 10px 15px;

                        h5 {
                            font-size: 16px;
                            margin-top: 0px;
                            font-weight: 600;
                            line-height: 22px;
                        }

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                        }
                    }
                }

                .proceed-btn {
                    background-color: #000;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 10px 15px;
                    border-color: #000;
                }
            }

            .section-heading {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 2px solid rgba($color: #000000, $alpha: 0.1);
                padding-bottom: 10px;

                .documents-heading {
                    font-weight: 700;
                    font-size: 22px;
                    margin-bottom: 0px;
                    margin-top: 0px;
                }

                .add-document {
                    background-color: transparent;
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                    border-color: transparent;
                    padding: 0px;
                }
            }

            .small-width {
                width: 200px;
                padding: 0px 15px;
                height: 35px;
            }

            .file-input {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .delete-btn {
                    background-color: transparent;
                }
            }
        }
    }

    /* COPYRIGHT INFORMATION */
        .copyright-info {
        background-color: #F4F4F4;
        padding: 15px 0;

        .copyright-links {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .info {
                align-items: center;

                p {
                    font-weight: 400;
                    color: #000;
                    font-size: 15px;
                    margin-bottom: 0px;
                }

                .links {
                    margin-top: 5px;

                    a {
                        color: #000;
                        font-size: 13px;
                        margin-right: 20px;
                    }
                }
            }

            .security-verifications {
                align-items: center;

                img {
                    height: 50px;
                    width: auto;
                    margin-left: 30px;
                }
            }
        }
    }
}
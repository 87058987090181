.old-header {
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.1);

    /* HEADER WRAP */
    .old-header-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;

        .logo img {
            width: 80px;
            height: auto;
        }

        /* HEADER RIGHT */
        .actions {
            display: flex;
            align-items: center;
            gap: 30px;

            .header-actions {
                text-decoration: none;
                color: #000;

                .link-text {
                    font-size: 14px;
                    font-weight: 600;
                }

                .cart-btn {
                    background-color: #000;
                    color: #FFF;
                    font-size: 14px;
                    font-weight: 600;
                    border-color: #000;

                    span {
                        padding-left: 5px;
                    }
                }
            }
        }

        /* NAVIGATION OPTIONS */
        .header-nav {
            .main-menu {
                .menu-link {
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 14px;
                    color: #000;
                    padding: 0 15px;
                }

                .menu-link:hover {
                    text-decoration: underline;
                }

                .header-dropdown {
                    .dropdown-toggle {
                        padding: 0 15px;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 14px;
                        color: #000;
                    }

                    .dropdown-menu {
                        width: auto;
                        left: 10px;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                        .dropdown-item {
                            font-size: 14px;
                            text-transform: uppercase;
                            color: #000;
                            font-weight: 600;
                            padding: 5px 10px;
                        }
                    }
                }
            }
        }
    }
}
.container-register{
    // border: 2px solid red;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#checkout-tabs{
    border: 2px soild green;
}

.tab-column{
    // border: 2px solid blue;
}

.register-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-column{
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
}

p{
    margin-bottom: 0px;
}

.business-type{
    padding: 10px;
    margin-bottom: 10px;
}

h5{
    margin-top: 10px;
}
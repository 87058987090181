.about-us {
    .section {
        img {
            width: 100%;
            height: auto;
        }
    }

    h4 {
        margin-bottom: 15px;
        font-weight: 700;
    }

    h5 {
        margin-bottom: 15px;
        font-weight: 600;
    }

    p {
        font-size: 15px;
    }

    video {
        width: 100%;
        height: auto;
    }

    .shopping-collection {
        h2 {
            font-weight: 700;
            text-align: center;
        }

        .collection-card {
            text-decoration: none;

            img {
                width: 100%;
                height: auto;
            }

            .collection-link {
                display: block;
                margin-top: 5px;
                font-weight: 500;
                margin-bottom: 0px;
                font-size: 22px;
                text-decoration: none;
                color: #000;

                .right-icon {
                    margin-left: 5px;
                }
            }

            .collection-link:hover {
                .right-icon {
                    margin-left: 10px;
                }
            }
        }
    }
}
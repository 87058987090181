.registration-landing {
    z-index: 0;
    /* BANNER */
    .banner {
        position: relative;
        text-align: center;
        padding: 40px 0px;
        background-image: url('../../../assets/banners/registration-landing.jpg');
        background-size: cover;
        z-index: 2;

        h5 {
            font-size: 25px;
            font-weight: 500;
            color: #FFF;
        }

        h1 {
            font-weight: 800;
            margin-top: 20px;
            font-size: 45px;
            color: #FFF;
        }

        .category-box {
            border-radius: 5px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            padding: 20px 25px;
            text-align: center;
            background-color: #FFF;
            height: 100%;

            .icon {
                height: 100px;
                width: 100px;
                border-radius: 50%;
                background-color: #F4F4F4;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 60px;
                    width: auto;
                }
            }

            .text {
                h5 {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 22px;
                    margin-top: 20px;
                    color: #000;
                }
            }

            .learn-more {
                display: block;
                padding: 0px;
                text-decoration: underline;
                background-color: #FFF;
                border-color: #FFF;
                font-size: 14px;
                font-weight: 400;
                color: #000;
                width: 100%;
            }

            .register-now {
                width: 100%;
                margin-top: 30px;
                background-color: #000;
                border-color: #000;
                font-weight: 600;
                font-size: 15px;
                padding: 10px 0px;
            }
        } 

        .retail-box {
            background-color: #FFF;
            margin-top: 30px;
            border-radius: 5px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            .box-header {
                background-color: #000;
                padding: 10px 0px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                h5 {
                    font-size: 16px;
                    font-weight: 600;
                    color: #FFF;
                }
            }

            .box-body {
                padding: 15px 0px;
                text-align: center;

                .retail-link {
                    text-decoration: none;
                    color: #000;

                    p {
                        font-size: 15px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;

                        span {
                            display: block;
                            margin-top: -5px;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }

        /* OVERLAY */
        .overlay {
            position: absolute;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }

    /* ACCOUNT HOLDER BENEFITS */
    .account-holder-benefits {
        padding: 50px 0;

        img {
            width: 350px;
            height: auto;
            border-radius: 50%;
            object-fit: cover;
        }

        .benefits-text {
            h4 {
                font-size: 30px;
                font-weight: 700;
            }

            h5 {
                font-size: 20px;
                font-weight: 600;
                margin-top: 15px;
                margin-bottom: 15px;
            }
        }
    }

    /* FAQS */
    .faqs {
        position: relative;
        padding: 60px 0;
        background-image: url('../../../assets/banners/FAQ-image.png');
        background-size: cover;
        z-index: 2;

        h4 {
            font-size: 35px;
            color: #FFF;
            font-weight: 700;
            margin-bottom: 25px;
        }

        .faq-box {
            margin-bottom: 20px;
        
            .question {
                color: #FFF;
                font-weight: 600;
            }

            .answer {
                font-weight: 500;
                color: #FFF;
            }
        }

        /* OVERLAY */
        .overlay {
            position: absolute;
            top: 0;
            background-color: rgba(0, 0, 0, 0.4);
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }
}
.header {
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.1);

  /* TOP HEADER */
  .top-header {
    padding: 10px 0px;
    background-color: #000;
    text-align: center;

    h5 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0px;
      color: #fff;
    }
  }

  /* TOP HEADER TWO */
  .top-header-two {
    padding: 10px 0px;
    background-color: #e5e3e3;
    text-align: center;

    h5 {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0px;
      color: #000;
    }
  }

  /* HEADER WRAP */
  .header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;

    .logo img {
      height: auto;
      width: 90px;
    }

    /* HEADER RIGHT */
    .header-right {
      .header-actions {
        position: relative;
        margin-right: 20px;

        .action-icon {
          font-size: 25px;
          fill: #000;
        }

        .count {
          background-color: #000;
          position: absolute;
          right: -5px;
          top: -5px;
          color: #fff;
          height: 15px;
          width: 15px;
          border-radius: 100%;
          font-weight: 500;
          font-size: 10px;
          text-align: center;
          line-height: 15px;
        }
      }
    }

    /* NAVIGATION OPTIONS */
    .header-nav {
      position: relative;
      margin-left: 20x;
      .main-menu {
        .menu-link {
          font-weight: 600;
          text-transform: uppercase;
          font-size: 14px;
          color: #000;
          padding: 0 15px;
        }
      }
    }
  }
}

.popover-body {
  padding: 0px;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  display: none;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::before {
  display: none;
}

/* CART POPUP */
.cart-popup {
  position: absolute;
  right: -10px;
  border: 1px solid #000 !important;
  background-color: #fff;
  width: 400px;
  padding: 15px;
  margin-top: 5px;
  z-index: 1000;

  h5 {
    font-size: 16px;
    font-weight: 700;
  }

  .cross-icon {
    cursor: pointer;
  }

  .products-added {
    width: 100%;

    .product-img-bought {
      width: 100%;
      height: auto;
    }

    .product-name {
      font-size: 16px;
      font-weight: 600;
    }

    p {
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 400;
    }

    .items-in-cart-link {
      text-decoration: none;
      color: #000;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      display: block;
    }

    .items-in-cart-link:hover {
      text-decoration: underline;
    }

    .checkout-btn {
      background-color: #000;
      width: 100%;
      font-size: 14px;
      border-color: #000;
      border-radius: 5px !important;
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: 600;
    }

    .paypal-btn {
      background-color: #ffc43a;
      width: 100%;
      border-color: #ffc43a;
      border-radius: 5px !important;
      padding-top: 5px;
      padding-bottom: 5px;

      img {
        width: auto;
        height: 20px;
      }
    }

    .view-cart-btn {
      background-color: #fff;
      width: 100%;
      font-size: 14px;
      text-align: center;
      border-color: #000;
      border-radius: 5px !important;
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: 600;
      color: #000;
    }

    .view-cart-btn:hover {
      background-color: #000;
      color: #fff;
    }
  }
}

.menu-content {
  width: 100%;
  color: white;
  display: none;
  background-color: black;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
  .parent-row {
    padding: 20px;
    .main-column {
      border-right: 1px solid white;
    }
  }
}

.menu-content-2 {
  width: 100%;
  color: black;
  display: block;
  background-color: white;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  align-items: center;
  .upper-row {
    width: 100%;
    border: 1px solid #ddcbab;
  }
  .parent-row {
    padding: 20px;
    padding-left: 65px;
    width: 100%;
    h5 {
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 5px;
    }
    .main-column {
      border-right: 1px solid #ddcbab;
    }
    .menu-column {
      padding-left: 25px;
    }
  }
}

.redirect-url {
  text-decoration: none !important;
  padding-right: 12px;
  color: black;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
}
.redirect-url:hover {
    text-decoration: none;

    color: black;
    transform: translateY(-5px);

  }

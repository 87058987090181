.product-listing{
    display: flex;
    padding: 10px;
    .filters{
        padding: 10px;
        border: 1px solid #c1c1c1;
        border-radius: 5px;
        margin-right: 10px;
        .category-list:hover {
            cursor: pointer;
        }
        .category-ul-list{
            list-style-type: none;
            margin-left: 0px;
            padding-left: 10px;
        }

        .category-ul-list:hover {
            
        }
        .cat-item{
            // border: 1px solid red;
            transition: 0.3s;
            font-size: 0.9em;
        }
        .cat-item:hover {
            // border: 1px solid #e5e5e5;
            // border-radius: 5px;
            font-size: 1em;
            transition: 0.3s;
            // padding: 10px;
        }
    }
    .filter-title{
        align-items: center;
        border: 1px solid #c1c1c1;
        border-radius: 5px;
        cursor: pointer;
        // display: flex;
        // height: 50px;
        width: 100%;
        margin-bottom: 10px;
        padding: 5px 10px;
        // margin-right: 10px;
        // margin-left: 10px;
        font-size: 0.9em;
    }
}

.selected-filters{
    display: flex;
    .filter-header{
      display: flex;
      .filter-header-title{
        padding: 0px 5px;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        margin-right: 5px;
        .close-icon{
          margin-left: 5px;
        }
      }
    }
    p{
      padding: 0px 5px;
      margin-left: 5px;
      border-left: 1px solid #e5e5e5;
    }
}

.price-value{
    padding-left: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}

.price-slider-div{
    padding: 10px;
    input {
        display: inline-block;
        vertical-align: middle;
        font-size: 1em;
        font-family: Arial, sans-serif;
      }
      
      input[type="range"] {
        -webkit-appearance: none;
        border: 1px solid black;
        margin-right: 15px;
        width: 100%;
        height: 7px;
        background: white;
        border-radius: 5px;
        background-image: linear-gradient(black, black);
        background-size: 70% 100%;
        background-repeat: no-repeat;
      }
      
      
      
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: white;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background .3s ease-in-out;
      }
      
      input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: white;
        cursor: ew-resize;
        box-shadow: 0 0 2px 0 #555;
        transition: background .3s ease-in-out;
      }
      
      input[type="range"]::-moz-range-thumb:hover {
        background: black;
      }
      
      
}

div.stars {
    width: 100%;
    // display: inline-block;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    // margin-right: 0px;
}
   
  input.star { display: none; }
   
  label.star {
    float: right;
    padding-left: 3px;
    font-size: 20px;
    color: black;
    transition: all .2s;
  }
   
  input.star:checked ~ label.star:before {
    content: '\f005';
    // color: #FD4;
    color: gold;
    transition: all .25s;

  }
   
//   input.star-5:checked ~ label.star:before {
//     color: #FE7;
//     text-shadow: 0 0 20px #952;
//   }
   
//   input.star-1:checked ~ label.star:before { color: #F62; }
   
//   label.star:hover { transform: rotate(-15deg) scale(1.3); }
   
  label.star:before {
    content: '\f006';
    font-family: FontAwesome;
  }

  .rating-main{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    font-size: small;
  }

  .left-product-stats{
    display: flex;
    align-items: center;
  }

  .column-header{
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    margin-left: 10px;
    // margin-left: 0px;
    p{
      // padding-left: 10px;  
      font-weight: 500;
    }
  }

  .availability{
    .filter-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .avail{
        align-items: center;
      }
    }
    .checkbox-div{
      // border: 1px solid red;
      padding-top: 3px;
    }
  }
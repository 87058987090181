.account {
    .login_wrap {
        border: 1px solid #E2E9E1;
        padding: 30px;
        border-radius: 10px;
    }

    .login_wrap h3 {
        font-weight: 700;
        font-size: 25px;
    }

    .login_wrap p {
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 20px;
        color: #465B52;
    }

    .login_wrap .login-form .login-btn {
        background-color: #005CB9;
        border-color: #005CB9;
        box-shadow: none;
        padding: 8px 40px;
        color: #FFF;
        border-radius: 5px;
        font-size: 15px;
    }

    .login_wrap .login-form .form-group {
        margin-bottom: 1rem;
    }

    .login_wrap .login-form .form-group .form-control {
        border: 1px solid #E2E9E1;
        border-radius: 4px;
        height: 45px;
        box-shadow: none;
        padding-left: 20px;
        color: #161C27;
        font-size: 14px;
        width: 100%;
        margin-bottom: 5px;
    }

    .forgot-pass {
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: 500;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        a {
            text-decoration: none;
            color: #465B52;
            cursor: pointer;
        }
    }

    .login_wrap .login-form .login-btn {
        background-color: #000;
        border-color: #000;
        box-shadow: none;
        padding: 8px 40px;
        color: #FFF;
        border-radius: 5px;
        font-size: 15px;
    }

    .password-icon {
        background-color: #FFF;
        padding: 0px;
        position: absolute;
        top: 10px;
        right: 15px;
        border: 0px solid transparent;
    }

    .password-icon:hover {
        background-color: #FFF;
    }

    .password-icon svg {
        fill: #000;
    }
}

.wholesale {

    .login-height {
        height: calc(100vh - 80px);
    }

    .wholesale-login {
        justify-content: center;
        align-items: center;
        height: 100%;

        img {
            display: flex;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px
        }

        .login_wrap {
            border: 1px solid #E2E9E1;
            padding: 30px;
            border-radius: 10px;

            .create-acc-link {
                margin-top: 15px;
                margin-bottom: 0px;
                font-size: 16px;

                a {
                    text-decoration: none;
                    color: #000;
                    font-weight: 600;
                }
            }
        }
    
        .login_wrap h3 {
            font-weight: 700;
            font-size: 25px;
        }
    
        .login_wrap p {
            font-weight: 400;
            font-size: 15px;
            margin-bottom: 20px;
            color: #465B52;
        }
    
        .login_wrap .login-form .login-btn {
            background-color: #005CB9;
            border-color: #005CB9;
            box-shadow: none;
            padding: 8px 40px;
            color: #FFF;
            border-radius: 5px;
            font-size: 15px;
        }
    
        .login_wrap .login-form .form-group {
            margin-bottom: 1rem;
        }
    
        .login_wrap .login-form .form-group .form-control {
            border: 1px solid #E2E9E1;
            border-radius: 4px;
            height: 45px;
            box-shadow: none;
            padding-left: 20px;
            color: #161C27;
            font-size: 14px;
            width: 100%;
            margin-bottom: 5px;
        }
    
        .forgot-pass {
            margin-bottom: 15px;
            font-size: 15px;
            font-weight: 500;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
    
            a {
                text-decoration: none;
                color: #465B52;
                cursor: pointer;
            }
        }
    
        .login_wrap .login-form .login-btn {
            background-color: #000;
            border-color: #000;
            box-shadow: none;
            padding: 8px 40px;
            color: #FFF;
            border-radius: 5px;
            font-size: 15px;
            width: 100%;
            font-weight: 600;
        }
    
        .password-icon {
            background-color: #FFF;
            padding: 0px;
            position: absolute;
            top: 10px;
            right: 15px;
            border: 0px solid transparent;
        }
    
        .password-icon:hover {
            background-color: #FFF;
        }
    
        .password-icon svg {
            fill: #000;
        }
    }
    
    /* COPYRIGHT INFORMATION */
    .copyright-info {
        background-color: #E3E3E5;
        padding: 15px 0;

        .copyright-links {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .info {
                align-items: center;

                p {
                    font-weight: 400;
                    color: #000;
                    font-size: 15px;
                    margin-bottom: 0px;
                }

                .links {
                    margin-top: 5px;

                    a {
                        color: #000;
                        font-size: 13px;
                        margin-right: 20px;
                    }
                }
            }

            .security-verifications {
                align-items: center;

                img {
                    height: 50px;
                    width: auto;
                    margin-left: 30px;
                }
            }
        }
    }
}
.checkout {
    .checkout-tabnames {
        .checkout-tabnames-item {
            margin-right: 40px;
            a {
                font-size: 30px;
                font-weight: 700;
                color: rgb(187, 187, 187);
                letter-spacing: -0.5px;
                padding-left: 0px;
            }

            .nav-link.active {
                background-color: #FFF;
                color: #000;
            }
        }
    }

    .shipping-address {
        h5 {
            font-weight: 600;
            font-size: 18px;
        }

        .address-box {
            position: relative;
            border: 2px solid rgb(225, 225, 225);
            border-radius: 5px;
            padding: 15px 20px;
            cursor: pointer;
            margin-top: 5px;
        }

        .address-box .selected {
            border: 2px solid green;
        }

        .selected {
            border: 2px solid green;
        }

        .address-box.selected .selected-icon {
            display: block;
        }

        .address-box:hover {
            border: 2px solid green;
        }

        .address-box .selected-icon {
            display: none;
            position: absolute;
            top: 5px;
            right: 5px;
        }

        .address-box .address-details {
            position: relative;
        }

        .address-box .address-details p {
            font-size: 14px;
            margin-bottom: 0px;
        }

        .address-box .address-details .address {
            margin-top: 0px;
        }

        .address-box .address-details .actions {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 10px;
        }

        .address-box .address-details .actions .edit-btn {
            padding: 0px;
            color: grey;
            font-style: italic;
            background-color: #FFF;
            border: 0px solid transparent;
            font-size: 14px;
            font-weight: 600;
        }

        .address-box .address-details .actions .edit-btn:hover {
            text-decoration: underline;
        }


        .address-box .address-details .actions .delete-btn {
            padding: 0px;
            color: red;
            font-style: italic;
            background-color: #FFF;
            border: 0px solid transparent;
            font-size: 14px;
            font-weight: 600;
            margin-left: 15px;
        }

        .address-box .address-details .actions .delete-btn:hover {
            text-decoration: underline;
        }

        .address-box.selected .selected-icon {
            display: block;
        }

        .address-box .selected-icon {
            display: none;
            position: absolute;
            top: 5px;
            right: 5px;
        }

        .add-new-address-btn {
            font-family: 'Figtree', sans-serif;
            font-weight: 700;
            padding: 0px;
            background-color: #FFF;
            border: 0px solid transparent;
            color: #000;
            font-size: 15px;
        }
        
        .add-new-address-btn:hover {
            background-color: #FFF;
            color: #000;
            text-decoration: underline;
        }
        
        .shipping-address-details {
            margin-top: 10px;

            .create-account-box {
                display: flex;
                align-items: center;
                margin-top: 25px;
                border: 1px solid #E2E9E1;
                border-radius: 5px;
                padding: 15px 15px;

                .radio-control {
                    height: 20px;
                    width: 20px;
                }

                .account-text {
                    margin-left: 15px;
                    h5 {
                        margin-bottom: 0px;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 15px;
                    }
                }
            }

            .login_wrap {
                border: 1px solid #E2E9E1;
                border-radius: 10px;
                margin-top: 10px;
                margin-left: 5px;
                padding: 5px;
            }
        
            .login_wrap h3 {
                font-weight: 700;
                font-size: 25px;
            }
        
            .login_wrap p {
                font-weight: 400;
                font-size: 15px;
                margin-bottom: 20px;
                color: #465B52;
            }
        
            .login_wrap .login-form .login-btn {
                background-color: #005CB9;
                border-color: #005CB9;
                box-shadow: none;
                padding: 8px 40px;
                color: #FFF;
                border-radius: 5px;
                font-size: 15px;
            }
        
            .login_wrap .login-form .form-group .form-control {
                border: 1px solid #E2E9E1;
                border-radius: 4px;
                height: 45px;
                box-shadow: none;
                padding-left: 20px;
                color: #161C27;
                font-size: 14px;
                width: 100%;
            }
        
            .forgot-pass {
                margin-bottom: 15px;
                font-size: 15px;
                font-weight: 500;
                display: flex;
                justify-content: flex-end;
                
                a {
                    text-decoration: none;
                    color: #465B52;
                    cursor: pointer;
                }
            }
        
            .login_wrap .login-form .login-btn {
                background-color: #000;
                border-color: #000;
                box-shadow: none;
                padding: 8px 40px;
                color: #FFF;
                border-radius: 5px;
                font-size: 15px;
            }
        
            .password-icon {
                background-color: #FFF;
                padding: 0px;
                position: absolute;
                top: 10px;
                right: 15px;
                border: 0px solid transparent;
            }
        
            .password-icon:hover {
                background-color: #FFF;
            }
        
            .password-icon svg {
                fill: #000;
            }
        }
    }

    .summary-heading {
        font-weight: 600;
        font-size: 22px;
    }

    .coupon-code-accordion {
        .accordion-item {
            border: 1px solid #D5D5D5;

            .coupon-code-accordion-header {
                button {
                    p {
                        margin-bottom: 0px;
        
                        span {
                            color: #8E8E90;
                            margin-right: 5px;
                        }
        
                        .text-black {
                            font-weight: 600;
                        }
                    }
                }
        
                .accordion-button::after {
                    background-size: 15px;
                    margin-top: 5px;
                    display: none;
                }
        
                .accordion-button.collapsed {
                    box-shadow: none;
                }
        
                .accordion-button:not(.collapsed) {
                    background-color: #FFF;
                    color: #000;
                    box-shadow: none;
                    border-bottom: 1px solid #D5D5D5;
                }
            }
        
            .coupon-code-accordion-body {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
        
                .coupon-code-btn {
                    width: 100%;
                    background-color: #000;
                    font-size: 14px;
                    font-weight: 600;
                    border-color: #000;
                }
            }
        }
    }
    .coupon-code-summary {
        border: 1px solid #22b25c;
        padding: 10px;
        border-radius: 7px;
        margin-bottom: 10px;
      }

    .order-summary {
        border: 1px solid #D5D5D5;
        border-radius: 5px;
        padding: 20px 25px;

        .items {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-weight: 500;
                font-size: 15px;
                margin-bottom: 0px;
            }
        }

        .after-pay-link {
            margin-top: 10px;
            font-size: 14px;
            text-align: center;
            margin-bottom: 0px;
        }
    }

    .items-box {
        border: 1px solid #E2E9E1;
        border-radius: 5px;
        padding: 15px 20px;

        .product-img-bought {
            width: 100%;
            height: auto;
        }

        .product-name {
            font-size: 16px;
            font-weight: 600;
        }

        p {
            font-size: 14px;
            margin-bottom: 2px;
        }
    }

    .checkout-btn {
        margin-top: 15px;
        background-color: #000;
        padding: 10px 40px;
        border-color: #000;
    }

    .entered-shipping-details {
        margin-top: 20px;
        h5 {
            font-size: 15px;
            color: grey;
            font-weight: 400;
        }

        .entered-details {
            margin-top: 10px;
        }

        .entered-details p {
            margin-bottom: 0px;
            font-weight: 500;
        }

        .edit-link {
            color: gray;
            margin-top: 15px;
            text-decoration: underline;
            display: block;
            font-size: 14px;
        }
    }

    .delivery-box {
        display: flex;
        align-items: center;
        border: 1px solid #E2E9E1;
        border-radius: 5px;
        padding: 10px 15px;
        margin-bottom: 10px;

        .radio-control {
            height: 20px;
            width: 20px;
        }

        .account-text {
            margin-left: 15px;

            h5 {
                margin-bottom: 0px;
                font-size: 16px;
                font-weight: 600;
            }

            p {
                margin-bottom: 0px;
                font-size: 15px;
                margin-top: 2px;
                font-weight: 400;
            }
        }
    }

    .edit-link {
        color: gray;
        margin-top: 15px;
        text-decoration: underline;
        display: block;
        font-size: 14px;
    }

    .back-btn {
        background-color: rgb(255, 255, 255); 
        color: #000;
        padding: 10px 20px 20px 5px;
        border-radius: 5px !important;
        border: none;
        cursor: pointer;
    }
    
    .back-btn:hover {
        background-color: #FFF;
        color: #000;
    }

    .order-complete {
        h4 {
            font-weight: 700;
        }

        .order {
            p {
                font-size: 14px;
                color: gray;
                margin-bottom: 5px;
            }

            h5 {
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
}
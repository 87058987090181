.resale-certificates {
    padding: 50px 0;

    h1 {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 25px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 15px;
    }

    ul {
        padding-left: 50px;

        li {
            margin-bottom: 10px;
        }
    }

    .certificates-link {
        margin-top: 25px;

        h5 {
            font-weight: 600;
        }

        .link-text {
            color: #000;
            font-weight: 400;
            font-size: 15px;
        }

        .certificate {
            border: 2px solid #000;
            padding: 10px 15px;
            border-radius: 5px;

            p {
                margin-bottom: 0px;
                font-weight: 500;
            }
        }
    }
}
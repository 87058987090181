/* VARIABLES */
@import './variables.scss';

/* LAYOUT AND PAGES STYLESHEETS */
@import './layout/header.scss';
@import './layout/footer.scss';
@import './pages/home/home.scss';
@import './pages/press/press.scss';
@import './pages/affiliate/affiliate.scss';
@import './pages/returns/returns.scss';
@import './pages/terms-of-use/terms-of-use.scss';
@import './pages/privacy-policy/privacy-policy.scss';
@import './pages/contact-us/contact-us.scss';
@import './pages/new-arrivals/new-arrivals.scss';
@import './pages/eye/eye.scss';
@import './pages/lips/lips.scss';
@import './pages/brow/brow.scss';
@import './pages/face/face.scss';
@import './pages/bestsellers/bestsellers.scss';
@import './pages/diy-brows/diy-brows.scss';
@import './pages/account/account.scss';
@import './pages/sets/sets.scss';
@import './pages/cart/cart.scss';
@import './pages/checkout/checkout.scss';
@import './pages/about-us/about-us.scss';
@import './pages/dashboard/dashboard.scss';
@import './pages/wishlist/wishlist.scss';
@import './pages/product-detail/product-detail.scss';
@import './responsive.scss';
@import './pages/brow-quiz/brow-quiz.scss';
@import './layout/loading.scss';
@import './pages/no-page-found/no-page-found.scss';
@import './pages/account/new-register.scss';
@import './components/DataTable.scss';
@import './components/ItemRow.scss';
@import './pages/shop/Shop.scss';
@import './pages/account/registration.scss';
@import './pages/shop/new-shop.scss';
@import './layout/new-header.scss';
@import './pages/account/wholesale-form.scss';
@import './pages/account/registration-landing.scss';
@import './pages/resale-certificates/resale-certificates.scss';
@import './pages/shop/wholesale-shop.scss';
@import './layout/old-header.scss';
@import 'react-range-slider-input/dist/style.css';
@import './pages/wholesale-registration/registration-landing.scss';
@import './pages/brow-kit/brow-kit.scss';

body {
    font-family: $font-family-base;
    overflow-x: hidden;
    margin: auto;
}

p {
    font-family: 'Figtree', sans-serif;
}

span {
    font-family: 'Figtree', sans-serif !important;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1500px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 30px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-3 {
    margin-bottom: 30px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-1 {
    margin-left: 10px !important;
}

.ml-2 {
    margin-left: 20px !important;
}

.pt-3 {
    padding-top: 30px !important;
}

.ml-3 {
    margin-left: 30px !important;
}

.ml-4 {
    margin-left: 40px !important;
}

.ml-5 {
    margin-left: 50px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.ptb-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.ptb-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.ptb-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.ptb-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.ptb-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-center {
    justify-content: center !important;
}

.align-items-center {
    align-items: center !important;
}

.border-top-section {
    border-top: 1px solid #D5D5D5 !important;
}

.form-group {
    position: relative;
}

.validation-error {
    font-family: 'Figtree', sans-serif;
    position: relative;
    margin-left: 2px;
  }
  
  .validation-error-select {
    font-family: 'Figtree', sans-serif;
    position: relative;
    margin-left: 2px;
  }

  .validation-error-phone {
    font-family: 'Figtree', sans-serif;
    position: relative;
    margin-left: 75px;
  }

  .edit-btn {
    padding: 0px;
    font-family: 'Figtree', sans-serif;
    color: grey;
    font-style: italic;
    background-color: #FFF;
    border: 0px solid transparent;
    font-size: 14px;
    font-weight: 600;
}

.edit-btn:hover {
    text-decoration: underline;
    background-color: #fff;
    color: grey;
}


.delete-btn {
    padding: 0px;
    font-family: 'Figtree', sans-serif;
    color: red;
    font-style: italic;
    background-color: #FFF;
    border: 0px solid transparent;
    font-size: 14px;
    font-weight: 600;
    margin-left: 15px;
}

.delete-btn-img {
    padding: 3px;
    font-family: "Figtree", sans-serif;
    color: red;
    font-style: italic;
    background-color: #FFF;
    border: 0px solid transparent;
    font-size: 14px;
    font-weight: 600;
    margin-left: 15px;
    position: absolute;
    top: 72px;
    left: 97px;
}

.delete-relative{
    padding: 3px;
    font-family: "Figtree", sans-serif;
    color: red;
    font-style: italic;
    background-color: #FFF;
    border: 0px solid transparent;
    font-size: 14px;
    font-weight: 600;
    margin-left: 15px;
    position: relative;
    top: -138px;
    left: 6px;
}

.delete-relative:hover {
    text-decoration: underline;
    background-color:  #fff;
    color:  red;
}

.delete-btn:hover {
    text-decoration: underline;
    background-color:  #fff;
    color:  red;
}


.delete-btn-img:hover {
    text-decoration: underline;
    background-color:  #fff;
    color:  red;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}

.para {
    background: #fff;
    padding: 10px 5px;
}

.form-control {
    position: relative;
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    height: 45px;
    box-shadow: none;
    padding-left: 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
}

.file-control {
    position: relative;
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    height: 45px;
    box-shadow: none;
    padding: 8px 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    background-color: #FFF;
}

.form-control::placeholder {
    color: #8E8E90;
    font-size: 14px;
}

.form-control:focus {
    border: 1px solid #000;
    box-shadow: none !important;
}

.select-control__control {
    height: 45px;
    font-size: 14px;
    border: 1px solid #E2E9E1 !important;
    padding-left: 10px;
    color: #8E8E90;
}

.select-control__indicator-separator {
    display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.phone-number-select .PhoneInputCountry {
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    padding: 15px;
    height: 45px;
    background-color: #FFF;
}

.phone-number-select .PhoneInputCountry .PhoneInputCountrySelect option {
    color: #465B52;
    font-size: 15px;
    margin-bottom: 10px;
}

.phone-number-select .PhoneInputInput {
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    height: 45px;
    box-shadow: none;
    color: #161C27;
    font-size: 14px;
    padding: 6px 12px 6px 20px;
    width: 100%;
    font-weight: 500;
}

.phone-number-select .PhoneInputInput::placeholder {
    font-size: 14px;
}

.textarea-control {
    display: block;
    width: 100%;
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    box-shadow: none;
    padding-left: 20px;
    padding-top: 20px;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 500;
}

.product-card {
    width: 100%;
    margin-bottom: 25px;
    position: relative;

    .product-img {
        background-color: #E8E8E8;

        .front-img {
            display: block;
        }

        .back-img {
            display: none;
        }
    }

    .product-img:hover {
        .front-img {
            display: none;
        }

        .back-img {
            display: block;
        }
    }

    .product-img img {
        width: 100%;
        height: auto;
    }

    .wishlist-option {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #FFF;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .wishlist-icon {
            font-size: 20px;
        }
    }

    .product-desc {
        background-color: #F4F4F4;
        padding: 10px 15px;

        h5 {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 16px;
            font-weight: 600;
        }

        .ratings {
            margin-top: 5px;
            display: flex;
            align-items: center;

            img {
                margin-right: 3px;
            }

            p {
                margin-bottom: 0px;
                color: #555555;
                font-size: 14px;
                margin-left: 5px;
            }
        }

        .add-to-bag {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            width: 100%;
            background-color: #FFF;
            border: 1px solid rgb(0, 0, 0, 0.1);
            border-radius: 0px !important;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 10px;

            h5 {
                color: #000;
                margin-bottom: 0px;
            }
        }

        .add-to-bag:hover {
            border: 1px solid rgb(0, 0, 0, 1);
        }
    }
}

.newsletter-modal {
    .modal-dialog {
        .modal-content {
            border-radius: 0px !important;
        }
    }
}

.newsletter-modalbody {
    border-radius: 0px !important;
    height: 465px;
    padding: 0px;

    img {
        width: 100%;
        height: 465px;
        object-fit: cover;
    }

    .newsletter-description {
        padding: 30px 40px;

        h5 {
            font-size: 16px;
            font-style: italic;
        }

        h2 {
            font-weight: 700;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0px;
        }

        .form-control {
            margin-top: 15px;
        }

        .small-text {
            font-size: 12px;
            margin-bottom: 0px;
            margin-left: 10px;
        }

        .sign-up-now-btn {
            margin-top: 20px;
            font-size: 14px;
            border-color: #000;
            font-weight: 600;
            width: 100%;
            background-color: #000;
        }

        .not-now-btn {
            margin-top: 10px;
            font-size: 14px;
            border-color: #000;
            font-weight: 600;
            color: #000;
            width: 100%;
            background-color: transparent;
            margin-bottom: 10px;
        }
    }
}

.select-control__option {
    font-size: 14px !important;
    font-weight: 500;
}

.validation-error {
    display: block;
    color: red;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 0px;
    font-weight: 500;
}
.accordion-header:focus {
    background-color: #f0f0f0 !important;
    /* Add any other styles you want */
  }
  .accordion-button:not(.collapsed) {
    background-color: #fff !important;
    color:#000
  }
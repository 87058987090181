.row-div{
    border-bottom: 1px solid #e5e5e5;
    align-items: center;
}

.row-div:hover{
    background-color: #f8f9fd;
}

.imp-col{
    font-size: 1.1em;
    font-weight: 700;
}

.item-image{
    max-width: 60%;
    height: auto;
}

.item-btn{
    background-color: white;
    color: black;
    width: 100%;
    border: 1px solid black;
}

.item-btn:hover{
    background-color: black;
    color: white;
}

.cart-col{
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
    height: 39px;
    .item-quant{
        width: 60%;
        height: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        padding-left: 5px;
        margin-bottom: 0px;
    }
    .cart-btn{
        width: auto;
        background-color: black;
        color: white;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        border: none;
        margin-bottom: 0px;

    }
}


.pop-over{
    // border: 1px solid red;
    width: 180px;
    .pop-body{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0px;
    }
}

.color-list{
    display: flex;
    width: 100%;
    padding: 5px 10px;
    .color-list-1{
        background-color: #7a3b85;
        // margin-right: 0px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
    .color-list-2{
        background-color: #c89390;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
    .color-list-3{
        background-color: #9e995f;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
    .color-list-4{
        background-color: #403332;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
    .color-list-5{
        background-color: #21366e;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
    p{
        padding-left: 15px;
        margin-left: 20px;
    }
}

.color-list:hover{
    cursor: pointer;
    background-color: #f8f9fd;
    border-radius: 5px;
}
.login-btn-modal{
    background-color: #000;
    border-color: #000;
    box-shadow: none;
    padding: 8px 40px;
    color: #FFF;
    border-radius: 5px;
    font-size: 15px;
    width: 100%;
    font-weight: 600;
}

.modal-form{
    margin-top: 20px;
}

.makeup_login{
    width: 100%;
    height: auto;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.modal-body {
    padding: 0px;
    padding-right: 10px;
    .modal-close{
        display: flex;
        justify-content: flex-end;
        margin: 0px;
        padding: 0px;
        .close-btn{
            width: 5px;
            height: 5px;
            margin-right: 10px;
        }
    }
    .login_wrap {
        // border: 1px solid #E2E9E1;
        padding: 30px;
        padding-top: 0px;
        border-radius: 10px;
    }

    .login_wrap h3 {
        font-weight: 700;
        font-size: 25px;
    }

    .login_wrap p {
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 20px;
        color: #465B52;
    }

    .login_wrap .login-form .login-btn {
        background-color: #005CB9;
        border-color: #005CB9;
        box-shadow: none;
        padding: 8px 40px;
        color: #FFF;
        border-radius: 5px;
        font-size: 15px;
    }

    .login_wrap .login-form .form-group {
        margin-bottom: 1rem;
    }

    .login_wrap .login-form .form-group .form-control {
        border: 1px solid #E2E9E1;
        border-radius: 4px;
        height: 45px;
        box-shadow: none;
        padding-left: 20px;
        color: #161C27;
        font-size: 14px;
        width: 100%;
        margin-bottom: 5px;
    }

    .forgot-pass {
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: 500;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        a {
            text-decoration: none;
            color: #465B52;
            cursor: pointer;
        }
    }

    .login_wrap .login-form .login-btn {
        background-color: #000;
        border-color: #000;
        box-shadow: none;
        padding: 8px 40px;
        color: #FFF;
        border-radius: 5px;
        font-size: 15px;
    }

    .password-icon {
        background-color: #FFF;
        padding: 0px;
        position: absolute;
        top: 10px;
        right: 15px;
        border: 0px solid transparent;
    }

    .password-icon:hover {
        background-color: #FFF;
    }

    .password-icon svg {
        fill: #000;
    }
}


@media (max-width: 996px){
    .makeup_login{
        display: none;
    }
    .close-btn{
        margin-top: 15px;
    }
}
.wholesale-shop {
  padding: 40px 0;

  h1 {
    text-align: center;
    font-weight: 700;
  }

  .product-list {
    margin-top: 40px;

    .product-box {
      border-bottom: 2px solid #f4f4f4;
      padding-bottom: 30px;

      .images-list {
        position: sticky;
        top: 10px;
      }

      .primary-img {
        border: 2px solid #e5e5e5;
        padding: 10px;
        border-radius: 5px;
        img {
          width: 100%;
          border-radius: 5px;
          height: auto;
        }
      }

      .product-detail-img-slider {
        margin-top: 10px;
        img {
          width: 100%;
          height: auto;
          border-radius: 5px;
          cursor: pointer;
        }

        .small-img-loading {
          height: 122px;
          width: 100%;
          border-radius: 5px;
        }
      }

      .swiper-button-next {
        height: 50px !important;
        width: 50px !important;
      }

      .swiper-button-prev {
        height: 50px !important;
        width: 50px !important;
      }

      .product-small-images {
        width: 90%;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
      }

      .swiper-button-next:after,
      .swiper-button-prev:after {
        background-color: #fff;
        font-size: 10px !important;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        font-weight: 700;
        color: #000;
      }

      .primary-img {
        text-align: center;
      }

      .details {
        h5 {
          font-size: 30px;
          font-weight: 700;
          margin-bottom: 15px;
        }

        .suggested-retail-price {
          font-size: 18px;
          font-weight: 400;
          margin-top: 0px;
          margin-bottom: 0px;
          position: relative;
        }
        .suggested-retail-price-blur-box {
          width: 100%;
          height: 60px;
          background-color: rgba($color: #ffffff, $alpha: 0.1);
          backdrop-filter: blur(7px);
          position: relative;
          margin-bottom: 10px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          z-index: 1;

          p {
            font-weight: 600;
            margin-bottom: 0px;
            margin-top: 10px;
          }
        }
        .margin-top-negative {
          margin-top: -50px;
        }

        .sku {
          margin-left: 5px;
          font-weight: 400;
          font-size: 15px;
        }

        .in-stock {
          background-color: rgba(171, 134, 75, 0.3);
          padding: 5px 15px;
          border-radius: 20px;
          font-size: 14px;
          font-weight: 600;
          margin-right: 15px;
        }

        .product-detail-description {
          border: 1px solid #e5e5e5 !important;
          border-radius: 5px !important;
          margin-bottom: 15px;

          .product-detail-description-header {
            button {
              font-weight: 600;
              font-size: 15px;
              border-radius: 5px;
            }
          }

          .accordion-button::after {
            content: "+";
            background-image: none !important;
            width: auto;
            height: auto;
            font-size: 15px;
          }

          .accordion-button:not(.collapsed) {
            background-color: #fff;
            box-shadow: none;
            color: #000;
            border-bottom: 1px solid #e5e5e5;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }

          .accordion-item .accordion-button.collapsed {
            box-shadow: none;
          }

          .product-detail-description-body {
            .description-loading {
              height: 200px;
            }

            p {
              font-family: "Figtree", sans-serif !important;
              font-size: 14px;
              margin-bottom: 5px;

              span {
                font-family: "Figtree", sans-serif !important;
                font-size: 14px;
                margin-bottom: 5px;
              }
            }

            ul {
              margin-top: 10px;
              font-family: "Figtree", sans-serif !important;

              li {
                font-family: "Figtree", sans-serif !important;
                font-size: 14px;
                margin-bottom: 5px;

                a {
                  span {
                    font-family: "Figtree", sans-serif !important;
                  }
                }
              }
            }
          }
        }

        .minimum-order {
          font-size: 18px;
          font-weight: 400;
        }

        .product-price {
          position: relative;
          width: 100%;
          margin-top: 10px;
          background-color: #f4f4f4;
          border-radius: 5px;

          .price-table-head {
            background-color: #f4f4f4;
            border-radius: 5px;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);

            th {
              font-size: 14px;
              font-weight: 600;
              padding: 10px 20px;
              border-top-left-radius: 5px;
            }
          }

          .price-table-body {
            tr {
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);

              td {
                font-size: 14px;
                font-weight: 600;
                padding: 8px 20px;
              }
            }
          }
        }

        .shades-selection {
          margin-top: 20px;
          background-color: #f4f4f4;
          border-radius: 5px;
          width: 100%;
          padding: 20px 20px;

          .shade-row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .shade-present {
              display: flex;
              align-items: center;
              width: 210px;

              .shade-color {
                height: 30px;
                width: 30px;
                border-radius: 50%;
                background-color: #be6c4b;
              }

              p {
                font-size: 15px;
                margin-bottom: 0px;
                font-weight: 500;
                margin-left: 10px;
              }
            }

            .quantity-counter {
              display: flex;
              align-items: center;
              width: 100px;
              margin: auto;

              .minus-counter {
                height: 42px;
                background-color: #fff;
                border-color: #e5e5e5;
                color: #000;
                font-size: 16px;
                border-right-color: transparent;
                border-bottom-right-radius: 0px;
                border-top-right-radius: 0px;
              }

              .count {
                height: 42px;
                width: 37px;
                background-color: #fff;
                color: #000;
                border-top: 1px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                  margin-bottom: 0px;
                  color: #000;
                }
              }

              .plus-counter {
                height: 42px;
                background-color: #fff;
                border-color: #e5e5e5;
                color: #000;
                font-size: 16px;
                border-left-color: transparent;
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
              }
            }

            .add-to-cart {
              background-color: #000;
              border-color: #000;
              font-size: 14px;
              font-weight: 600;
              padding: 10px 30px;
            }
          }
        }

        .blur-box {
          height: 100%;
          width: 100%;
          background-color: rgba($color: #ffffff, $alpha: 0.1);
          backdrop-filter: blur(9px);
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          z-index: 2;

          img {
            width: 50px;
            height: 50px;
          }

          p {
            font-weight: 600;
            margin-bottom: 0px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

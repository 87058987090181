.press {
    /* BANNER */
    .banner img {
        width: 100%;
        height: auto;
    }

    /* PRESS CONTENT */
    .press-content {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        margin-top: 20px;
        line-height: 25px;
    }

    /* PRESS ARTICLES */
    .articles-content {
        margin-top: 20px;

        .image {
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .video-frame {
            width: 100%;
        }

        .article-link {
            display: block;
            font-weight: 500;
            margin-bottom: 0px;
            font-size: 22px;
            margin-top: 15px;
            text-decoration: none;
            color: #000;

            .right-icon {
                margin-left: 5px;
            }
        }

        .article-link:hover {
            .right-icon {
                margin-left: 10px;
            }
        }
    }

    /* SHOP COLLECTION */
    .shopping-collection {
        h2 {
            font-weight: 700;
            text-align: center;
        }

        .collection-card {
            text-decoration: none;
            
            img {
                width: 100%;
                height: auto;
            }

            .collection-link {
                display: block;
                margin-top: 5px;
                font-weight: 500;
                margin-bottom: 0px;
                font-size: 22px;
                text-decoration: none;
                color: #000;

                .right-icon {
                    margin-left: 5px;
                }
            }

            .collection-link:hover {
                .right-icon {
                    margin-left: 10px;
                }
            }
        }
    }
}
.kit-row {
  padding: 10px;

  .kit-image-col {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .kit-image {
      width: 85%;
      margin-top: 15px;
      object-fit: cover;
    }
  }

  .kit-description {
    background-color: #f6f6f6;
    padding: 10px;
    border-radius: 10px;

    .kit-contents {
      // padding: 10px;
      .content-item {
        display: flex;
        justify-content: space-between;
        margin: 10px 0px;
        background-color: white;
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        h4 {
          margin-top: 5px;
        }
        .paragraph {
          margin-top: 10px;
        }
        .shade-present {
          display: flex;
          align-items: center;
          width: 210px;

          .shade-color {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background-color: #be6c4b;
          }
        }
        .left-content {
          display: flex;
          align-items: center;
        }
        .content-img {
          width: 110px;
          margin-right: 10px;
        }
      }
    }

    .one-price {
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }

    .add-to-cart {
      display: flex;
      align-items: center;
      .kit-btn {
        background-color: black;
        outline: none;
        width: 150px;
        height: 40px;
        border: none;
      }
      .quantity-field {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 40px;
        margin: 0 auto;
      }
      .quantity-field .value-button {
        border: 1px solid #ddd;
        margin: 0px;
        width: 40px;
        height: 100%;
        padding: 0;
        background: #eee;
        outline: none;
        cursor: pointer;
      }
      .quantity-field .value-button:hover {
        background: rgb(230, 230, 230);
      }

      .quantity-field .value-button:active {
        background: rgb(210, 210, 210);
      }

      .quantity-field .decrease-button {
        margin-right: -4px;
        border-radius: 8px 0 0 8px;
      }

      .quantity-field .increase-button {
        margin-left: -4px;
        border-radius: 0 8px 8px 0;
      }
      .quantity-field .number {
        display: inline-block;
        text-align: center;
        border: none;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin: 0px;
        width: 40px;
        height: 100%;
        line-height: 40px;
        font-size: 11pt;
        box-sizing: border-box;
        background: white;
      }
    }

    .details {
      margin-top: 10px;
      span {
        font-weight: 600;
      }
    }
  }
}

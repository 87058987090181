.diy-brows {
    h2 {
        font-weight: 700;
        text-align: center;
    }

    .banner {
        margin: 20px 0px;

        img {
            width: 100%;
            height: auto;
        }
    }

    h3 {
        text-align: center;
    }

    .video-frame {
        width: 100%;
        height: auto;   
    }

    .youtube-video-frame {
        width: 100%;
        margin-top: 10px;
    }

    h5 {
        text-align: center;
    }
}
.home {
    /* BANNERS */
    .banners {
        .banner-video {
            video {
                width: 100%;
            }
        }

        .swiper-button-next {
            height: 50px !important;
            width: 50px !important;
        }

        .swiper-button-prev {
            height: 50px !important;
            width: 50px !important;
        }

        .swiper-button-next:after,
        .swiper-button-prev:after {
            background-color: #FFF;
            font-size: 15px !important;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            font-weight: 700;
            color: #000;
        }
    }
    .banner{
        display: flex;
        justify-content: center;
    }
    /* BESTSELLERS */
    .bestsellers {
        .section-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            h2 {
                font-size: 25px;
                font-weight: 700;
            }

            .shop-products {
                color: #000;
                font-weight: 400;
            }
        }

        .swiper-button-next {
            height: 50px !important;
            width: 50px !important;
        }

        .swiper-button-prev {
            height: 50px !important;
            width: 50px !important;
        }

        .swiper-button-next:after, .swiper-button-prev:after {
            background-color: #FFF;
            font-size: 15px !important;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            font-weight: 700;
            color: #000;
        }
    }

    /* BROWBAR BY REEMA */
    .browbar-by-reema {
        h2 {
            font-weight: 700;
            text-align: center;
        }
    
        p {
            font-weight: 400;
            margin-bottom: 10px;
            text-align: center;
        }

        .browbar-banner {
            img {
                width: 100%;
                height: auto;
            }
        }

        .youtube-video {
            margin-top: 25px;

            .video-frame {
                width: 100%;
            }

            .glam-link {
                display: block;
                font-weight: 500;
                margin-bottom: 0px;
                font-size: 22px;
                margin-top: 10px;
                text-decoration: none;
                color: #000;

                .right-icon {
                    margin-left: 5px;
                }
            }

            .glam-link:hover {
                .right-icon {
                    margin-left: 10px;
                }
            }
        }
    }

    /* ART OF THE BROW */
    .art-of-the-brow {
        h2 {
            font-weight: 700;
            text-align: center;
        }

        p {
            font-weight: 400;
            margin-bottom: 0px;
            text-align: center;
        }

        .youtube-video {
            margin-bottom: 25px;
            .video-frame {
                width: 100%;
            }

            .glam-link {
                display: block;
                font-weight: 500;
                margin-bottom: 0px;
                font-size: 22px;
                margin-top: 10px;
                text-decoration: none;
                color: #000;

                .right-icon {
                    margin-left: 5px;
                }
            }

            .glam-link:hover {
                .right-icon {
                    margin-left: 10px;
                }
            }
        }
    }

    /* TESTIMONIALS */
    .testimonials {
        .testimonial-card {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 20px 50px;
            border-radius: 5px;
            background-image: linear-gradient(to top left,#FBC0D0, #EEEEFF, #E0FFFF);

            .user-avatar {
                margin-left: auto;
                margin-right: auto;
                height: 120px;
                width: 120px;
                border-radius: 50%;

                img {
                    width: 100%;
                    object-fit: none;
                    border-radius: 50%;
                }
            }

            .ratings {
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    text-align: center;
                    margin-right: 10px;
                }
            }

            .description {
                margin-top: 15px;

                h4 {
                    font-weight: 600;
                }
            }
        }

        .swiper-scrollbar-drag {
            background-color: rgba($color: #000000, $alpha: 0.3);
        }
    }
    
    /* GIFT OF GLAM */
    .gift-of-glam {
        h2 {
            font-weight: 700;
            text-align: center;
        }

        .youtube-video {
            padding-top: 20px;
            padding-bottom: 20px;

            .video-frame {
                width: 100%;
            }

            .glam-link {
                display: block;
                font-weight: 500;
                margin-bottom: 0px;
                font-size: 25px;
                margin-top: 10px;
                text-decoration: none;
                color: #000;

                .right-icon {
                    margin-left: 5px;
                }
            }

            .glam-link:hover {
                .right-icon {
                    margin-left: 10px;
                }
            }
        }
    }

    /* BBR BENEFITS */
    .benefits {
        background-color: #000;
        text-align: center;

        h2 {
            color: #FFF;
            font-weight: 700;
        }

        .benefits-card {
            text-align: center;

            h5 {
                color: #FFF;
                font-size: 15px;
                margin-top: 10px;
                line-height: 25px;
            }
        }
    }

    /* PRESS BANNER */
    .press-banner {
        img {
            width: 100%;
            height: auto;
        }
    }
}